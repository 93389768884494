import * as React from 'react';
import { useContext } from 'react';
import cn from 'classnames';
import { CollapseContext, CollapseContextProps } from 'styleguide/components/SimpleCollapse/SimpleCollapse';
import Collapse from 'react-collapse';

const CollapsePanel = ({
  className,
  children,
  ...otherProps
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const collapseContext: CollapseContextProps = useContext<CollapseContextProps>(CollapseContext);

  return (
    <Collapse
      {...otherProps}
      isOpened={collapseContext.open}
      theme={
        collapseContext.animate
          ? {
              collapse: cn('transition-height h-8  duration-300', className),
            }
          : className
      }
    >
      {children}
    </Collapse>
  );
};

export default CollapsePanel;
