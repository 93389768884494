import * as React from 'react';
import { LineItem } from 'api/orders/types';
import { Product } from 'contexts/AppContext/types';
import { formatMoney } from 'utils/money';
import Image from 'styleguide/components/Image/Image';
import AppContext, { AppContextType } from 'contexts/AppContext/AppContext';
import startCase from 'lodash-es/startCase';
import SimpleCollapse, { CollapseContext } from 'styleguide/components/SimpleCollapse/SimpleCollapse';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
import CollapseButton from 'styleguide/components/SimpleCollapse/CollapseButton';
import CollapsePanel from 'styleguide/components/SimpleCollapse/CollapsePanel';

interface Props {
  item: LineItem;
}
const LineItemRow = ({ item: { quoterJson, product, description, total } }: Props) => {
  const appContext: AppContextType = React.useContext<AppContextType>(AppContext);
  const quantity: number = parseInt(quoterJson.documentCount, 10);
  const pages: number = parseInt(quoterJson.documentPageCount, 10);
  const name: string = quoterJson.name !== '' ? quoterJson.name : null;

  const getProductImage = (productName: string) => {
    const prod: Product = appContext.store.products.find((p: Product): boolean => p.name === productName);
    return prod ? prod.images.master[0].url.product : '';
  };

  return (
    <div className="mb-6 flex items-start justify-between">
      <div className="flex">
        <Image
          alt={product.name}
          url={getProductImage(product.name)}
          className="h-18 mr-2 w-14 self-start object-contain lg:h-20 lg:w-16"
        />
        <div className="flex flex-col space-y-2">
          <div className="flex flex-col justify-start">
            {!!name && (
              <p className="m-0 line-clamp-2 font-hvMedium text-base font-medium leading-5 text-gray-400">
                {name}
              </p>
            )}
            <p className="m-0 line-clamp-2 font-hvBold text-lg font-bold leading-4 lg:text-xl">
              {product.name}
            </p>
            {pages > 0 ? (
              <p className="m-0 font-hvMedium text-xs font-medium leading-4 text-gray-500 lg:text-sm">
                {`${quantity} qty x ${pages} pages`}
              </p>
            ) : (
              <p className="m-0 line-clamp-1 font-hvMedium text-xs font-medium leading-4 text-gray-500">
                {`${quantity} qty`}
              </p>
            )}
          </div>
          {description && (
            <SimpleCollapse className="flex flex-col">
              <CollapseContext.Consumer>
                {({ open }) => (
                  <>
                    <CollapseButton
                      className="bold inline-flex grow select-none items-center
                    self-start font-hvBold text-sm"
                    >
                      View Order Specs
                      <ChevronRightIcon
                        className={cn(open ? 'rotate-90' : '', 'transform duration-300 ease-in-out')}
                        width="1.25em"
                        height="1.25em"
                        viewBox="0 0 20 20"
                      />
                    </CollapseButton>
                    <CollapsePanel className="relative">
                      {description.map((item, i) => {
                        const key = Object.keys(item)[0];
                        return (
                          <div key={i} className="list-none px-1 text-xs text-gray-600 ">
                            <span className="col-start-1 font-hvBold">{`${startCase(key)}:`}</span>{' '}
                            <span className="col-start-2">{item[key]}</span>
                          </div>
                        );
                      })}
                    </CollapsePanel>
                  </>
                )}
              </CollapseContext.Consumer>
            </SimpleCollapse>
          )}
        </div>
      </div>
      <div className="paragraph-mobile self-center">{formatMoney(total)}</div>
    </div>
  );
};

export default LineItemRow;
