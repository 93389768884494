import React from 'react';
import cn from 'classnames';
import {
  InformationCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import { TypeOptions } from 'react-toastify';

interface CalloutProps {
  message: string;
  description?: string;
  type?: TypeOptions;
  classes?: string;
}

const Callout = ({ message, description, type, ...props }: CalloutProps) => {
  let icon: JSX.Element = null;
  let toastClasses: string = null;

  switch (type) {
    case 'success':
      icon = (
        <CheckCircleIcon
          className={cn(
            'text-green-500',
            'ml-1 h-5 w-5 transform transition duration-150 group-hover:text-green-500',
          )}
        />
      );
      toastClasses = 'bg-green-50 border border-green-500';
      break;
    case 'info':
      icon = (
        <InformationCircleIcon
          className={cn(
            'text-blue-500',
            'ml-1 h-5 w-5 transform transition duration-150 group-hover:text-blue-500',
          )}
        />
      );
      toastClasses = 'bg-blue-50 border border-blue-300';
      break;
    case 'warning':
      icon = (
        <ExclamationCircleIcon
          className={cn(
            'text-yellow-500',
            'ml-1 h-5 w-5 transform transition duration-150 group-hover:text-yellow-500',
          )}
        />
      );
      toastClasses = 'bg-yellow-50 border border-yellow-700';
      break;
    case 'error':
    default:
      icon = (
        <XCircleIcon
          className={cn(
            'text-red-500',
            'ml-1 h-5 w-5 transform transition duration-150 group-hover:text-red-500',
          )}
        />
      );
      toastClasses = 'bg-red-50 border border-red-300';
      break;
  }

  return (
    <div className={cn('rounded-xl px-3 py-2 shadow-md', toastClasses, props.classes)}>
      <div
        className={cn(
          'mr-8 flex text-sm font-semibold leading-5',
          type === 'success' ? 'text-green-500' : '',
          type === 'warning' ? 'text-yellow-500' : '',
          type === 'info' ? 'text-blue-500' : '',
          type === 'error' ? 'text-red-500' : '',
          description ? 'mb-2' : 'items-center',
        )}
      >
        <div className={cn(description ? 'self-start' : 'flex items-center', 'mr-3')}>{icon}</div>
        <div className="flex-col">
          <div className={cn(description ? 'mb-2' : '', 'font-hvBold text-sm')}>{message}</div>
          {description && <div className="text-xs font-medium leading-4 text-gray-500"> {description}</div>}
        </div>
      </div>
    </div>
  );
};

export default Callout;
