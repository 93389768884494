import * as React from 'react';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';

const MessageSupport = () => (
  <div className="mt-6 flex flex-col items-center rounded-xl border border-neutral-200 bg-neutral-50 px-4 py-6 text-center">
    <p className="!mb-3 font-hvBold">Got Questions? Message Support</p>
    <p className="mt-0 max-w-sm text-sm">
      Our support team is here for questions about billing, shipping, account changes and files.
    </p>
    <ButtonAsNativeLink buttonType="link" target="/contact-us" color="dark" outline size="md">
      Message Support
    </ButtonAsNativeLink>
  </div>
);

export default MessageSupport;
