import * as React from 'react';
import startCase from 'lodash-es/startCase';
import Button from 'styleguide/components/Button/Button';
import LineItemRow from 'bundles/App/pages/CheckoutPage/Steps/ReviewOrderStep/LineItemRow';
import Chip from 'styleguide/components/Chip/Chip';
import { Order, LineItem, FileVersion, Upload } from 'api/orders/types';
import { fileStateToColor } from 'utils/orderStateMap';
import Callout from 'styleguide/components/Callout/Callout';
import SimpleCollapse, { CollapseContext } from 'styleguide/components/SimpleCollapse/SimpleCollapse';
import cn from 'classnames';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import A from 'styleguide/components/Links/A';
import TextDivider from 'styleguide/components/Dividers/TextDivider';
import CollapsePanel from 'styleguide/components/SimpleCollapse/CollapsePanel';
import CollapseButton from 'styleguide/components/SimpleCollapse/CollapseButton';

interface Props {
  order: Order;
  setUploadingToLineItem: (item: LineItem) => void;
  setViewingFileVersion: (fileVersion: FileVersion) => void;
  className?: string;
}

const OrderDetails = ({ order, className, ...props }: Props) => {
  const getFileStatus = (state: string) => startCase(state.split('_').join(' '));

  const isUploadDisabled = (item: LineItem) => {
    if (item.fileVersions.length === 0 || order.state !== 'waiting_on_customer_files') {
      return true;
    }
    return ['waiting_on_approval', 'approved', 'files_being_reviewed'].includes(
      item.fileVersions[item.fileVersions.length - 1].state,
    );
  };

  const isReupload = (item: LineItem) =>
    item.fileVersions.length > 2 &&
    item.fileVersions[item.fileVersions.length - 1].state === 'waiting_on_files' &&
    item.fileVersions[item.fileVersions.length - 2].state === 'rejected';

  const isWaitingOnFiles = (item: LineItem) =>
    item.fileVersions.length > 0 &&
    item.fileVersions[item.fileVersions.length - 1].state === 'waiting_on_files';

  return (
    <div className={className}>
      {order.lineItems &&
        order.lineItems.map(item => (
          <div key={item.id} className="mb-6 border-b border-neutral-200 last:border-b-0">
            {isWaitingOnFiles(item) && (
              <Callout
                classes="mb-5 !min-h-[56px] !max-w-[600px]"
                type="warning"
                message="We need some files"
                description="Make sure to upload your files on time to get keep your delivery date."
              />
            )}
            <LineItemRow item={item} />
            <div className="mb-6 grid grid-cols-2 space-y-2 md:grid-cols-3">
              {item.fileVersions &&
                item.fileVersions.map((fileVersion, idx) =>
                  idx < item.fileVersions.length - 1 ? (
                    <>
                      {idx === 0 && <TextDivider className="col-span-full" content="Past File Uploads" />}
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                      <div
                        className="cursor-pointer text-sm text-blue underline first:mt-2"
                        onClick={() => {
                          props.setUploadingToLineItem(item);
                          props.setViewingFileVersion(fileVersion);
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <span>{idx === 0 ? 'Original Files' : `File Revision ${idx}`}</span>
                      </div>
                      <Chip
                        className="col-span-1 justify-self-start md:col-span-2"
                        title={getFileStatus(fileVersion.state)}
                        textColor={
                          fileStateToColor[getFileStatus(fileVersion.state)]?.textColor || 'text-neutral-50'
                        }
                        backgroundColor={
                          fileStateToColor[getFileStatus(fileVersion.state)]?.backgroundColor ||
                          'bg-neutral-400'
                        }
                      />
                    </>
                  ) : (
                    <>
                      <TextDivider className="col-span-full" content="Current File Set" />
                      <SimpleCollapse defaultOpen using={Fragment}>
                        <CollapseContext.Consumer>
                          {({ open }) => (
                            <CollapseButton className="inline-flex items-center">
                              <div className="cursor-pointer font-hvMedium text-sm font-medium text-blue">
                                <span>{idx === 0 ? 'Original Files' : `File Revision ${idx}`}</span>
                              </div>
                              <ChevronRightIcon
                                className={cn(
                                  'text-blue',
                                  open ? 'rotate-90' : '',
                                  'transform duration-300 ease-in-out',
                                )}
                                width="1.25em"
                                height="1.25em"
                                viewBox="0 0 20 20"
                              />
                            </CollapseButton>
                          )}
                        </CollapseContext.Consumer>
                        <Chip
                          className="items-center self-start justify-self-start"
                          title={getFileStatus(fileVersion.state)}
                          textColor={
                            fileStateToColor[getFileStatus(fileVersion.state)]?.textColor || 'text-neutral-50'
                          }
                          backgroundColor={
                            fileStateToColor[getFileStatus(fileVersion.state)]?.backgroundColor ||
                            'bg-neutral-400'
                          }
                        />
                        <Button
                          type="button"
                          color={isReupload(item) ? 'blue' : 'dark'}
                          onClick={() => {
                            props.setUploadingToLineItem(item);
                          }}
                          outline={!isReupload(item)}
                          size="sm"
                          disabled={isUploadDisabled(item)}
                          className="!self-start md:!h-6 md:justify-self-end -md:col-span-full -md:row-start-[100] "
                        >
                          {isReupload(item) ? 'Re-upload Files' : 'Upload Files'}
                        </Button>
                        <CollapsePanel className="col-span-full pl-2">
                          {fileVersion.uploads.length > 0 ? (
                            <>
                              {fileVersion.uploads.map((file: Upload, i: number) => (
                                <A
                                  underline="active"
                                  color="blue"
                                  href={file.url}
                                  key={`file-${i}`}
                                  className="[overflow-wrap:anywhere] !text-sm"
                                >
                                  {file.name}
                                </A>
                              ))}
                            </>
                          ) : (
                            <div className="[overflow-wrap:anywhere] text-sm italic">
                              No files uploaded for this file set.
                            </div>
                          )}
                        </CollapsePanel>
                      </SimpleCollapse>
                    </>
                  ),
                )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default OrderDetails;
