import { JSXElementConstructor, useContext } from 'react';
import cn from 'classnames';
import * as React from 'react';
import { CollapseContext, CollapseContextProps } from 'styleguide/components/SimpleCollapse/SimpleCollapse';

interface ButtonProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  defaultIcon?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  using?: keyof JSX.IntrinsicElements | JSXElementConstructor<any>;
}

const CollapseButton = ({ using: ReactTag = 'button', children, className, ...otherProps }: ButtonProps) => {
  const collapseContext: CollapseContextProps = useContext<CollapseContextProps>(CollapseContext);
  return (
    <ReactTag
      className={cn('cursor-pointer', className)}
      {...otherProps}
      onClick={() => collapseContext.setOpen(!collapseContext.open)}
    >
      {children}
    </ReactTag>
  );
};

export default CollapseButton;
