import * as React from 'react';
import Modal from 'styleguide/components/Modal/Modal';
import ProductDescription from 'styleguide/components/ProductsTable/ProductDescription/ProductDescription';
import { FileVersion, LineItem, Order } from 'api/orders/types';
import FileStatus from 'styleguide/components/FileStatus/FileStatus';
import Button from 'styleguide/components/Button/Button';

interface Props {
  order?: Order;
  lineItem?: LineItem;
  onClose?: () => void;
  fileVersion?: FileVersion;
  setUploadingToLineItem: (item: LineItem) => void;
}

const ViewFilesModal = ({ ...props }: Props) => {
  const isUploadDisabled = (item: LineItem) => {
    if (!item.fileVersions || props.order.state !== 'waiting_on_customer_files') {
      return true;
    }
    return ['waiting_on_approval', 'approved', 'files_being_reviewed'].includes(
      item.fileVersions[item.fileVersions.length - 1].state,
    );
  };

  const isReupload = (item: LineItem) =>
    item.fileVersions.length > 2 &&
    item.fileVersions[item.fileVersions.length - 1].state === 'waiting_on_files' &&
    item.fileVersions[item.fileVersions.length - 2].state === 'rejected';

  return (
    <Modal
      className="!max-w-2xl"
      onClose={props.onClose}
      shown
      title={<h2 className="font-hvBold text-2xl">View Files</h2>}
    >
      <ProductDescription order={props.order} lineItem={props.lineItem} initiallyExpanded withBackground />

      <FileStatus
        fileVersionState={props.fileVersion.state}
        filesInUpload={props.fileVersion.uploads}
        lineItem={props.lineItem}
      />
      {!isUploadDisabled(props.lineItem) && (
        <Button
          type="button"
          color={isReupload(props.lineItem) ? 'blue' : 'dark'}
          onClick={() => {
            props.onClose();
            props.setUploadingToLineItem(props.lineItem);
          }}
          outline={!isReupload(props.lineItem)}
          size="sm"
        >
          {isReupload(props.lineItem) ? 'Re-upload Files' : 'Upload Files'}
        </Button>
      )}
    </Modal>
  );
};

export default ViewFilesModal;
