import * as React from 'react';
import cn from 'classnames';
import { Order } from 'api/orders/types';
import MessageSupport from 'bundles/App/pages/OrderDetailsPage/MessageSupport/MessageSupport';
import OrderAddressDetails from 'bundles/App/pages/OrderDetailsPage/OrderAddressDetails/OrderAddressDetails';
import Collapse from 'styleguide/components/Collapse/Collapse';
import IfMobile from 'styleguide/components/Conditional/IfMobile';
import IfTabletOrDesktop from 'styleguide/components/Conditional/IfTabletOrDesktop';
import Chip from 'styleguide/components/Chip/Chip';
import { orderStateToColor } from 'utils/orderStateMap';

interface Props {
  order?: Order;
  className?: string;
}

const ShippingDetails = ({ order, className }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleCollapseItemToggle = () => setOpen(!open);

  return (
    <>
      <IfMobile>
        <Collapse className={className}>
          <Collapse.Item
            title={
              <div className="flex flex-col items-start space-y-0.5 p-4 text-sm">
                <p className="m-0 flex font-hvBold">Order Details</p>
                <p className="m-0 font-hvMedium text-gray-500">Order #{order.number}</p>
                <Chip
                  title={order.customerState}
                  textColor={orderStateToColor[order.customerState]?.textColor || 'text-neutral-50'}
                  backgroundColor={
                    orderStateToColor[order.customerState]?.backgroundColor || 'bg-neutral-400'
                  }
                />
              </div>
            }
            body={<OrderAddressDetails order={order} open={open} />}
            classNameTitle={cn(
              'border border-neutral-200 rounded-xl',
              open ? 'border-b-0 rounded-b-none' : '',
            )}
            iconClassName="!w-2 !h-2"
            onToggle={handleCollapseItemToggle}
            isWide
          />
        </Collapse>
      </IfMobile>
      <IfTabletOrDesktop className={className}>
        <OrderAddressDetails order={order} />
        <MessageSupport />
      </IfTabletOrDesktop>
    </>
  );
};

export default ShippingDetails;
