import * as React from 'react';
import {
  useState,
  createContext,
  useEffect,
  JSXElementConstructor,
  forwardRef,
  useImperativeHandle,
} from 'react';

export interface CollapseContextProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  defaultOpen: boolean;
  animate: boolean;
}

export interface SimpleCollapseProps extends React.HTMLProps<HTMLDivElement> {
  defaultOpen?: boolean;
  animate?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  using?: keyof JSX.IntrinsicElements | JSXElementConstructor<any>;
}

export const CollapseContext = createContext<CollapseContextProps>(null);

const SimpleCollapse = forwardRef(
  (
    {
      using: ReactTag = 'div',
      children,
      defaultOpen = false,
      animate = true,
      ...otherProps
    }: SimpleCollapseProps,
    ref,
  ) => {
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
      setOpen(defaultOpen);
    }, []);

    const value = React.useMemo(
      () => ({
        open,
        setOpen,
        defaultOpen,
        animate,
      }),
      [animate, defaultOpen, open],
    );

    useImperativeHandle(ref, () => ({
      open,
      setOpen,
    }));

    return (
      <CollapseContext.Provider value={value}>
        <ReactTag {...otherProps}>{children}</ReactTag>
      </CollapseContext.Provider>
    );
  },
);

export default SimpleCollapse;
