import * as React from 'react';
import { submitFiles } from 'api/cartPage';
import { SharedErrors, Status } from 'libs/utils/api/types';
import toasts from 'utils/toast';
import UploadFilesModal from 'styleguide/components/ProductsTable/UploadFilesModal/UploadFilesModal';
import Loader from 'styleguide/components/Loader/Loader';
import Meta from 'styleguide/components/Meta/Meta';
import { useHistory, useLocation } from 'react-router';
import Footer from 'styleguide/components/Footer/Footer';
import queryString from 'qs';
import { getOrderFromToken } from 'api/orders/orders';
import { Order, Upload, LineItem, FileVersion } from 'api/orders/types';
import Chip from 'styleguide/components/Chip/Chip';
import { orderStateToColor } from 'utils/orderStateMap';
import ViewFilesModal from 'bundles/App/pages/OrderDetailsPage/ViewFilesModal/ViewFilesModal';
import OrderDetails from 'bundles/App/pages/OrderDetailsPage/OrderDetails/OrderDetails';
import ShippingDetails from 'bundles/App/pages/OrderDetailsPage/ShippingDetails/ShippingDetails';
import IfMobile from 'styleguide/components/Conditional/IfMobile';
import MessageSupport from 'bundles/App/pages/OrderDetailsPage/MessageSupport/MessageSupport';
import CustomPrompt from 'styleguide/components/CustomPrompt/CustomPrompt';
import Button from 'styleguide/components/Button/Button';

const OrderDetailsPage = () => {
  const [order, setOrder] = React.useState<Order>(null);
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [uploadingToLineItem, setUploadingToLineItem] = React.useState<LineItem>(null);
  const [viewingFileVersion, setViewingFileVersion] = React.useState<FileVersion>(null);

  const location = useLocation();
  const history = useHistory();
  const orderNumber: string = queryString.parse(location.search, { ignoreQueryPrefix: true })
    .order_number as string;
  const authToken: string = queryString.parse(location.search, { ignoreQueryPrefix: true })
    .auth_token as string;

  React.useEffect(() => {
    if (orderNumber) {
      const handleSuccess = (response: Order) => {
        setOrder(response);
      };

      const handleFailure = (res: SharedErrors) => {
        toasts.create(res.message, 'error');
      };

      getOrderFromToken(orderNumber, authToken).then(res => {
        if (res.status === Status.Ok) {
          handleSuccess(res.payload);
        } else if (res.status === Status.ClientError) {
          handleFailure(res.payload);
        }
        if (res.status === Status.AuthError) {
          history.push('/sign-in');
        }
      });
    }
  }, []);

  const notifyWhenEmptyUploads = () => {
    toasts.create('Please add new files to all items before submitting for approval.', 'error', '', {
      autoClose: 4000,
      containerId: 'autoclose',
    });
  };

  const submitFilesHandler = () => {
    const missingUploads = !order.lineItems.every(
      li => li.fileVersions[li.fileVersions.length - 1].uploads.length > 0,
    );

    if (missingUploads) {
      notifyWhenEmptyUploads();
    } else {
      submitFiles(orderNumber, authToken).then(res => {
        if (res.status === Status.Ok) {
          toasts.create('Successfully submitted new files for approval!', 'success');
          setOrder(res.payload);
        }
      });
    }
  };

  return (
    <>
      <Meta
        title="Order Details"
        description=""
        keywords=""
        robots={['noindex']}
        canonical="https://www.printivity.com/order-details"
      />
      <CustomPrompt
        title="Forgot to submit your files?"
        description="To avoid delaying your order, please upload files on the previous screen before proceeding."
        buttonCancelText="Return to files"
        buttonConfirmText="Proceed Anyway"
        onCancel={() => false}
        onConfirm={() => true}
        when={!!dirty}
      />
      <div className="mx-auto mt-4 max-w-base px-2">
        <div className="grid place-content-start gap-x-4 -md:hidden">
          <div className="col-span-2 font-hvMedium text-lg text-gray-500">Order Details</div>
          <div className="font-hvBold text-3xl font-bold">Order Number: {orderNumber}</div>
          {!order ? (
            <div className="flex justify-center">
              <Loader size="lg" />
            </div>
          ) : (
            <Chip
              title={order.customerState}
              textColor={orderStateToColor[order.customerState]?.textColor || 'text-neutral-50'}
              backgroundColor={orderStateToColor[order.customerState]?.backgroundColor || 'bg-neutral-400'}
              className="md:!py-0 md:px-4 md:!text-2xl"
            />
          )}
        </div>
        {/* <TileHeader title="Order Details" subText="Manage your order details." /> */}
        {!order && (
          <div className="mt-8 flex justify-center">
            <Loader size="xl" />
          </div>
        )}
        {uploadingToLineItem && !viewingFileVersion && (
          <UploadFilesModal
            order={order}
            lineItem={uploadingToLineItem}
            owner={uploadingToLineItem.fileVersions[uploadingToLineItem.fileVersions.length - 1]}
            ownerType="Mgx::FileVersion"
            onClose={() => setUploadingToLineItem(null)}
            onAddUpload={(dispatch, id: number, upload: Upload) => {
              const lineItem = order.lineItems.find(li => li.fileVersions.some(fv => fv.id === id));
              const fileVersion = lineItem.fileVersions.find(fv => fv.id === id);
              fileVersion.uploads.push(upload);
              fileVersion.state = 'files-uploaded';

              setOrder({
                ...order,
                lineItems: order.lineItems.map(li => ({
                  ...li,
                  fileVersions: [...li.fileVersions.map(fv => (fv.id === fileVersion.id ? fileVersion : fv))],
                })),
              });
              setDirty(true);
            }}
            onRemoveUpload={(dispatch, id: number) => {
              const lineItem = order.lineItems.find(li =>
                li.fileVersions.find(fv => fv.uploads.some(u => u.id === id)),
              );
              const fileVersion = lineItem.fileVersions.find(fv => fv.uploads.some(u => u.id === id));
              const newFileVersion = {
                ...fileVersion,
                uploads: [...fileVersion.uploads.filter(upload => upload.id !== id)],
              };
              if (newFileVersion.uploads.length === 0) {
                fileVersion.state = 'waiting_on_files';
              }

              setOrder({
                ...order,
                lineItems: order.lineItems.map(li => ({
                  ...li,
                  fileVersions: [
                    ...li.fileVersions.map(fv => (fv.id === newFileVersion.id ? newFileVersion : fv)),
                  ],
                })),
              });
              setDirty(true);
            }}
          />
        )}
        {viewingFileVersion && (
          <ViewFilesModal
            onClose={() => {
              setViewingFileVersion(null);
              setUploadingToLineItem(null);
            }}
            order={order}
            lineItem={uploadingToLineItem}
            fileVersion={viewingFileVersion}
            setUploadingToLineItem={setUploadingToLineItem}
          />
        )}
        {order && (
          <div className="mt-4">
            {order.state === 'waiting_on_customer_files' && (
              <div className="mb-8 flex flex-row justify-center">
                <div className="flex flex-col justify-start">
                  <ol className="list-inside">
                    <span className="font-hvMedium text-base font-medium leading-8">
                      There are two steps to upload new files:
                    </span>
                    <li>
                      Click the &quot;Upload Files&quot; button next to the product details to upload any
                      new/revised files
                    </li>
                    <li>
                      Once you&apos;ve finished uploading all new/revised files, click the &quot;Submit
                      Files&quot; button below to reactivate your order
                    </li>
                  </ol>
                  <div className="mt-4 self-center">
                    <Button type="button" color="orange" onClick={() => submitFilesHandler()}>
                      Submit Files
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <div className="flex -md:flex-col-reverse">
              <OrderDetails
                className="mr-6 rounded-xl border border-neutral-200 p-4 md:basis-7/12 -md:w-full"
                order={order}
                setUploadingToLineItem={setUploadingToLineItem}
                setViewingFileVersion={setViewingFileVersion}
              />
              {!!order.shippingAddress && (
                <ShippingDetails order={order} className="md:!mt-0 md:basis-5/12 -md:mb-6 -md:w-full" />
              )}
            </div>
            <IfMobile>
              <MessageSupport />
            </IfMobile>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default OrderDetailsPage;
