import * as React from 'react';
import Modal from 'styleguide/components/Modal/Modal';
import { useHistory } from 'react-router';
import Button from 'styleguide/components/Button/Button';

interface Props {
  title: string;
  description?: string;
  onCancel?: () => boolean;
  onConfirm?: () => boolean;
  buttonCancelText?: string;
  buttonConfirmText?: string;
  when?: boolean;
}

const CustomPrompt = ({ when, onCancel, onConfirm, ...props }: Props) => {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [currentPath, setCurrentPath] = React.useState('');

  React.useEffect(() => {
    if (when) {
      history.block(prompt => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleConfirm = React.useCallback(async () => {
    if (onConfirm) {
      const canRoute = await Promise.resolve(onConfirm());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onConfirm]);

  const handleCancel = React.useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return (
    <Modal
      className="!max-w-2xl"
      onClose={onCancel}
      shown={showPrompt}
      title={<h2 className="font-hvBold text-2xl">{props.title}</h2>}
    >
      <>
        <p className="mb-6">{props.description}</p>
        <div className="flex justify-end -md:flex-col">
          <Button
            type="button"
            color="blue"
            className="mr-2 -md:mb-2 -md:mr-0"
            onClick={() => {
              handleCancel();
            }}
            size="md"
          >
            {props.buttonCancelText}
          </Button>
          <Button
            type="button"
            color="dark"
            onClick={() => {
              handleConfirm();
            }}
            size="md"
            outline
          >
            {props.buttonConfirmText}
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default CustomPrompt;
