import * as React from 'react';
import cn from 'classnames';

export interface TextDividerProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  content: string;
  dividerLineColorClass?: string;
  contentOverrideClasses?: string;
}

const TextDivider = ({
  content,
  dividerLineColorClass,
  contentOverrideClasses,
  ...otherProps
}: TextDividerProps): JSX.Element => (
  <div className={cn('relative', otherProps.className)}>
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div
        className={cn('w-full border-0 border-t border-solid', dividerLineColorClass || 'border-neutral-200')}
      />
    </div>
    <div className="relative flex justify-center">
      <span
        className={contentOverrideClasses || 'bg-white px-3 text-sm font-semibold leading-6 text-gray-500'}
      >
        {content}
      </span>
    </div>
  </div>
);

export default TextDivider;
