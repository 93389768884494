import * as React from 'react';
import cn from 'classnames';
import { Order } from 'api/orders/types';
import Button from 'styleguide/components/Button/Button';
import AddressItem from 'bundles/App/pages/OrderDetailsPage/Address/Address';
import { formatMoney } from 'utils/money';
import Chip from 'styleguide/components/Chip/Chip';
import { nameFromKey, paymentStateKeyToColor } from 'utils/orderStateMap';
import TextDivider from 'styleguide/components/Dividers/TextDivider';

interface Props {
  order?: Order;
  open?: boolean;
}

const OrderAddressDetails = ({ order, open }: Props) => (
  <div
    className={cn(
      'flex flex-col rounded-xl border border-neutral-200 px-4 py-4',
      open ? 'rounded-t-none border-t-0 pt-0' : '',
    )}
  >
    <div className="flex flex-col">
      <div className={cn('flex flex-col')}>
        <p className="m-0 self-center font-hvMedium text-lg -md:hidden">Order # {order.number}</p>
        {/* <p className="m-0 mt-0.5 self-center font-hvBold -md:hidden">Order Summary</p> */}
        <TextDivider content="Order Summary" />
        <div className="mt-2 space-y-1">
          <div className="paragraph-medium-mobile flex justify-between text-gray-500">
            <div>Item{order.lineItems.length > 1 ? 's ' : ' '}Subtotal</div>
            <div>{formatMoney(order.subtotal)}</div>
          </div>
          <div className="paragraph-medium-mobile flex justify-between text-gray-500">
            <div>Shipping & Handling</div>
            <div>{formatMoney(order.shippingTotal)}</div>
          </div>
          <div className="paragraph-medium-mobile flex justify-between text-gray-500">
            <div>Promotion Discount</div>
            <div>{formatMoney(order.promotionTotal)}</div>
          </div>
          <div className="paragraph-medium-mobile flex justify-between text-gray-500">
            <div>Tax</div>
            <div>{formatMoney(order.taxTotal)}</div>
          </div>
        </div>
        <div className="my-2 flex justify-between font-hvBold">
          <div>Grand Total</div>
          <div>{formatMoney(order.total)}</div>
        </div>
        <TextDivider content="Payment Summary" />
        <div className="mt-2 flex flex-col space-y-1">
          <div className="paragraph-medium-mobile flex justify-between text-gray-500">
            <div>
              Payment{order.payments.filter(payment => payment.state === 'completed').length > 1 ? 's ' : ' '}
              Total
            </div>
            <div>{formatMoney(order.paymentTotal)}</div>
          </div>
          {order.refunds?.length > 0 && (
            <div className="paragraph-medium-mobile flex justify-between text-gray-500">
              <div>
                Refund{order.refunds.length > 1 ? 's ' : ' '}
                Total
              </div>
              <div>
                {formatMoney(order.refunds.reduce((refundTotal, refund) => refundTotal + refund.amount, 0))}
              </div>
            </div>
          )}
          <div className="flex justify-between font-hvBold">
            <div>Remaining Balance</div>
            <div>{formatMoney(order.outstandingBalance)}</div>
          </div>
          {order.paymentState &&
            (order.paymentState === 'balance_due' ? (
              <div className="mt-6 flex justify-around px-20 -2xl:px-4 -md:!mb-2 2xl:!mb-2">
                <Button.AsNativeLink
                  buttonType="link"
                  color="orange"
                  target={`/orders/${order.number}/payments/new`}
                  size="sm"
                >
                  Pay Now
                </Button.AsNativeLink>
                {order.paymentState && (
                  <Chip
                    title={nameFromKey(order.paymentState)}
                    textColor={paymentStateKeyToColor[order.paymentState]?.textColor || 'text-neutral-50'}
                    backgroundColor={
                      paymentStateKeyToColor[order.paymentState]?.backgroundColor || 'bg-neutral-400'
                    }
                    className="self-stretch"
                  />
                )}
              </div>
            ) : (
              <Chip
                title={nameFromKey(order.paymentState)}
                textColor={paymentStateKeyToColor[order.paymentState]?.textColor || 'text-neutral-50'}
                backgroundColor={
                  paymentStateKeyToColor[order.paymentState]?.backgroundColor || 'bg-neutral-400'
                }
                className="self-end"
              />
            ))}
        </div>
      </div>
      <TextDivider content="Addresses" />
      <div className="flex justify-around -2xl:flex-col">
        <AddressItem title="Shipping" address={order.shippingAddress} />
        <AddressItem title="Billing" address={order.billingAddress} />
      </div>
      <div className="mt-6 flex justify-around px-20 -2xl:flex-col -2xl:px-4 -sm:flex-row">
        <Button.AsNativeLink
          buttonType="link"
          className="-2xl:mb-6 -sm:mb-0 -sm:mr-4"
          color="dark"
          target={`/account/orders/${order.number}/tracking`}
          size="sm"
        >
          Track Order
        </Button.AsNativeLink>
        <Button.AsNativeLink
          buttonType="anchor"
          className=""
          target={`/account/invoice/${order.number}.pdf`}
          targetBlank
          color="dark"
          outline
          size="sm"
        >
          PDF Invoice
        </Button.AsNativeLink>
      </div>
    </div>
  </div>
);

export default OrderAddressDetails;
